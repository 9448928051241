import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { getDataTestId } from "src/utils";
import { getTheme } from "src/selectors";
import { metrikaActions } from "src/actions";
import { Props } from "./types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useStep = (props: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const theme = useSelector(getTheme);

  const { play } = React.useContext(AudioContext);

  const getStepProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
    }),
    [content, theme]
  );

  const increment = React.useCallback(() => {
    play("step");
    const value = props.value + props.step;
    if (value <= props.max) {
      props.onChange(value);
    } else {
      props.onChange(props.max);
    }
  }, [play, props]);

  const decrement = React.useCallback(() => {
    play("step");
    const value = props.value - props.step;
    if (value >= props.min) {
      props.onChange(value);
    } else {
      props.onChange(props.min);
    }
  }, [play, props]);

  const getDecProps = React.useCallback(
    () => ({
      className: cn(styles.button, styles.dec, {
        [styles.selected]: props.selected,
      }),
      onClick: decrement,
      ...getDataTestId("button-minus"),
    }),
    [decrement, props.selected]
  );

  const getIncProps = React.useCallback(
    () => ({
      className: cn(styles.button, styles.inc, {
        [styles.selected]: props.selected,
      }),
      onClick: increment,
      ...getDataTestId("button-plus"),
    }),
    [increment, props.selected]
  );

  const onClick = React.useCallback(() => {
    play("step");
    props.onClick();
    dispatch(metrikaActions.send({ target: "AmountDynamicClick" }));
  }, [dispatch, play, props]);

  const getValueProps = React.useCallback(
    () => ({
      className: cn(styles.value, { [styles.selected]: props.selected }),
      ["data-selected"]: props.selected,
      onClick,
      ...getDataTestId("button-dynamic-amount"),
    }),
    [onClick, props.selected]
  );

  const values = React.useMemo(
    () => ({
      content,
    }),
    [content]
  );

  return { getDecProps, getIncProps, getStepProps, getValueProps, values };
};
