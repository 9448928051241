import React from "react";

import { useStatistics } from "./hooks";

export const StatisticsComponent = React.memo(() => {
  const {
    getCupProps,
    getFooterProps,
    getLineProps,
    getLiveProps,
    getPlaceProps,
    getStatisticsProps,
    values,
  } = useStatistics();
  return (
    <div {...getStatisticsProps()}>
      <div className={values.styles.body}>
        {values.statistics.map((statistic, i) => (
          <div key={i} className={values.styles.statistic}>
            <img {...getCupProps(i)} />
            <div {...getPlaceProps(i + 1)}>
              {statistic.map((line, j) => (
                <span key={j} {...getLineProps(line)}>
                  {line}
                </span>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div {...getFooterProps()}>
        <span>ПОСЛЕДНИЕ 10 ГОНОК</span>
      </div>
      <button {...getLiveProps()} />
    </div>
  );
});

StatisticsComponent.displayName = "StatisticsComponent";
