import React from "react";
import cn from "classnames";

import { useSelector } from "react-redux";

import { AudioContext } from "src/contexts";
import { getAmounts } from "src/components/amounts/model";
import { getDataTestId } from "src/utils";
import { getTheme } from "src/selectors";
import { metrikaActions } from "src/actions";
import { Props } from "./types";
import { useAppDispatch, useContent } from "src/hooks";

import styles from "./style.module.scss";

export const useAmounts = (props: Props) => {
  const content = useContent();

  const dispatch = useAppDispatch();

  const amounts = useSelector(getAmounts);
  const theme = useSelector(getTheme);

  const { play } = React.useContext(AudioContext);

  const getAmountsProps = React.useCallback(
    () => ({
      className: cn(styles[content], styles[theme]),
    }),
    [content, theme]
  );

  const getButtonProps = React.useCallback(
    ({ value }: { value: number }) => ({
      className: cn(styles.button, {
        [styles.selected]: value === props.value,
      }),
      ["data-selected"]: value === props.value,
      onClick: () => {
        play("amount");
        props.onClick(value);
        dispatch(metrikaActions.send({ target: "AmountStaticClick" }));
      },
      ...getDataTestId("button-amount"),
    }),
    [dispatch, play, props]
  );

  const values = React.useMemo(
    () => ({
      amounts,
    }),
    [amounts]
  );

  return { getAmountsProps, getButtonProps, values };
};
